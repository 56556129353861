
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { authService } from "@/auth/authService";
import { AuthRole } from "@/auth/auth.constants";
import { api } from "@/api/api";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { ApiGetRoleDto } from "@/api/generated/Api";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import ResourceRolesModal from "@/components/administration/resourceRoles/ResourceRolesModal.vue";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { LoadingType } from "@/shared/enums/loading-type.enum";

export default defineComponent({
  name: "ResourceRolesPage",
  components: { BaseModal, ResourceRolesModal, BaseLayout, BaseTableFiltered },

  setup() {
    const search = ref("");
    const getRoles = ref<ApiGetRoleDto[]>([]);
    const modalHeadline = "Legg til Ressursrolle";
    const modalData = ref(getInitialModalData());

    onMounted(async () => {
      getRoles.value = (await api.role.getRolesAsync()).data;
    });

    const onModalClose = () => {
      loadResourceRoles();
    };
    const canUpsert = authService.hasRoles(AuthRole.ModifyRole);

    const openNewResourceRole = useOpenModal(ModalType.Add, "ressursrolle", modalData);
    const openEditResourceRole = useOpenModal(ModalType.Edit, "ressursrolle", modalData);
    const displayResourceRole = useOpenModal(ModalType.Display, "Vis ressursrolle", modalData);

    const loadResourceRoles = () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        getRoles.value = (await api.role.getRolesAsync()).data;
      });
    };

    return {
      loadResourceRoles,
      canUpsert,
      onModalClose,
      modalData,
      openNewResourceRole,
      openEditResourceRole,
      displayResourceRole,
      getRoles,
      search,
      headers,
      modalHeadline,
      modalType: ModalType.Add,
    };
  },
});

const headers = [
  { text: "Navn", value: "name" },
  { text: "Handlinger", value: "actions" },
  {
    text: "Type",
    value: "isForVocationalSchool",
  },
  {
    text: "Beskrivelse",
    value: "description",
  },
  { text: "Ressurs", value: "isResource" },
  { text: "Status", value: "isActive" },
  { text: "Kan kombineres", value: "cannotBeCombined" },
  { text: "Krever sertifisering", value: "requiresCertification" },
];
