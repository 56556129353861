var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('section', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "Rollenavn",
      "data-cy": "roleName",
      "rules": [function (v) {
        return !!v || 'Fyll inn navn på rolle.';
      }],
      "required": "",
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.roleValues.name,
      callback: function callback($$v) {
        _vm.$set(_vm.roleValues, "name", $$v);
      },
      expression: "roleValues.name"
    }
  }), _c('v-switch', {
    attrs: {
      "readonly": _vm.readonly,
      "value": false,
      "title": "Er ressurs",
      "inset": "",
      "label": _vm.roleValues.isResource ? 'Er ressurs' : 'Ikke ressurs'
    },
    model: {
      value: _vm.roleValues.isResource,
      callback: function callback($$v) {
        _vm.$set(_vm.roleValues, "isResource", $$v);
      },
      expression: "roleValues.isResource"
    }
  }), _c('v-switch', {
    attrs: {
      "data-cy": "isActiveRole",
      "readonly": _vm.readonly,
      "value": false,
      "inset": "",
      "label": _vm.roleValues.isActive ? 'Er aktiv' : 'Inaktiv'
    },
    model: {
      value: _vm.roleValues.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.roleValues, "isActive", $$v);
      },
      expression: "roleValues.isActive"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "roleDescription",
      "label": "Beskrivelse",
      "readonly": _vm.readonly
    },
    model: {
      value: _vm.roleValues.description,
      callback: function callback($$v) {
        _vm.$set(_vm.roleValues, "description", $$v);
      },
      expression: "roleValues.description"
    }
  }), _c('v-switch', {
    attrs: {
      "data-cy": "isActive",
      "readonly": _vm.readonly,
      "value": false,
      "title": "Er ressurs",
      "inset": "",
      "label": _vm.roleValues.isForVocationalSchool ? 'Fagskole' : 'Ikke fagskole'
    },
    model: {
      value: _vm.roleValues.isForVocationalSchool,
      callback: function callback($$v) {
        _vm.$set(_vm.roleValues, "isForVocationalSchool", $$v);
      },
      expression: "roleValues.isForVocationalSchool"
    }
  })], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }