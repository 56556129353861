
import { api } from "@/api/api";
import { ApiGetRoleDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";

const getAreaInitialData = (): ApiGetRoleDto => ({
  name: "",
  isActive: true,
  isResource: true,
  isForRegularCourse: false,
  isForVocationalSchool: false,
  cannotBeCombined: false,
  requiresCertification: false,
  description: "",
  inserted: "",
  insertedBy: 0,
  updated: "",
  updatedBy: 0,
});

export default defineComponent({
  name: "ResourceRolesModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    role: {
      type: Object as PropType<ApiGetRoleDto>,
      default: getAreaInitialData,
    },
  },
  setup({ modalType, role }, context) {
    const store = useStore<StoreState>();
    const roleValues = ref(deepCloneObject(role));
    const isActive = ref(role?.isActive ?? false);

    const onSubmit = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        if (modalType === ModalType.Add) {
          await api.role.createRoleAsync(roleValues.value);
          openNotification(store, NotificationItemType.Success, "Ressursrollen er lagt til");
        } else if (modalType === ModalType.Edit) {
          if (!roleValues) {
            openNotification(store, NotificationItemType.Error, "Ugyldig ressursrolle");
            return;
          }
          if (roleValues.value.name) {
            const name = roleValues.value.name.toString();
            // solution does not allow for editing of name as roles are retrieved from backend by name
            await api.role.updateRoleAsync(name, roleValues.value);
            openNotification(store, NotificationItemType.Success, "Ressursrollen  er oppdatert");
          }
        }
        context.emit("close");
        context.emit("update");
      });
    };

    return {
      roleValues,
      isActive,
      onSubmit,
      validateNotEmpty,
      readonly: computed(() => modalType === ModalType.Display),
    };
  },
});
